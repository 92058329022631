import da from './lang/da.js';
import de from './lang/de.js';
import en from './lang/en.js';
import fr from './lang/fr.js';
import it from './lang/it.js';
import nl from './lang/nl.js';
import pl from './lang/pl.js';
import sv from './lang/sv.js';

const messages = {
    ...da,
    ...de,
    ...en,
    ...fr,
    ...it,
    ...nl,
    ...pl,
    ...sv,
};

export default defineI18nConfig(() => ({
    legacy: false,
    globalInjection: true,
    locale: 'en',
    messages,
}));
